import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  createStyles,
  useMediaQuery,
  useTheme,
  makeStyles,
  Typography,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './card-image-title-description.scss';
import Fade from 'react-reveal/Fade';
import Scrollbar from '../scrollbar';
import moment from 'moment';
import CustomLink from '../custom-link';
import { navigate } from 'gatsby';
import Clamp from 'react-multiline-clamp';
import { Fade as MaterialFade } from '@material-ui/core';

const AnimatedCard = ({ image, title, body, height, date, type, bio = null, readMore = false, isImageFade = true }) => {
  const [isHovering, setIsHovering] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const useStyles = makeStyles((theme) =>
    createStyles({
      cornerCardBorder: {
        border: `5px solid ${theme.palette.background.default} !important`,
        borderRadius: '3px',
        transition: 'all 0.3s',
        backgroundColor: theme.palette.background.default,
        borderColor: isHovering ? `${theme.palette.primary.main} !important` : `transparent !important`,
        '&::before': {
          backgroundColor: theme.palette.background.default,
        },
        '&::after': {
          backgroundColor: theme.palette.background.default,
        },
      },
      card: {
        maxHeight: '600px',
        minHeight: '600px',
      },
      borderGlow: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '101%',
        height: '100.5%',
        zIndex: 1,
        boxShadow: '0px 0px 2px 2px #94bd1f',
      },
    })
  );
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Fade bottom>
        <Card
          elevation={0}
          square
          style={{
            position: 'relative',
            overflow: 'hidden',
            cursor: 'pointer',
          }}
          className='card-image-title-description'
          onClick={
            readMore
              ? () => navigate(readMore)
              : () => {
                  setIsHovering(true);
                }
          }
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onContextMenu={(e) => e.preventDefault()}>
          {bio && bio !== 'NULL' && bio !== 'null' && isHovering ? (
            <>
              {date || type ? (
                <Box display='flex' position='absolute' top='2%' right={0} left={0} zIndex='tooltip' p={1}>
                  {type ? (
                    <Box flexGrow={1}>
                      <Typography variant='body1' color='primary'>
                        {type.toUpperCase()}
                      </Typography>
                    </Box>
                  ) : null}
                  {date ? (
                    <Box>
                      <Typography variant='body1' color='primary'>
                        {moment(date).format('DD') + ' ' + moment(date).format('MMM').toUpperCase()}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              ) : null}

              <MaterialFade timeout={400} in={true}>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  flexDirection='column'
                  position='absolute'
                  style={{
                    background: 'rgb(7 14 64 / 79%)',
                  }}
                  top='5px'
                  bottom='5px'
                  left='5px'
                  right='5px'
                  zIndex='1000000'
                  overflow='hidden'
                  p={2}>
                  <Box className='text-uppercase' height='77%' overflow='hidden'>
                    <Clamp withTooltip={false} lines={16}>
                      <Typography dangerouslySetInnerHTML={{ __html: bio }} variant='body2' color='textPrimary' />
                    </Clamp>
                  </Box>
                  <Box height='11%'>
                    <Typography color='primary' variant='body2' onClick={handleClickOpenDialog}>
                      Read More
                    </Typography>
                  </Box>
                </Box>
              </MaterialFade>
            </>
          ) : null}
          <Box className={[classes.cornerCardBorder, 'cornerCardBorder'].join(' ')}>
            <Box className='borderGlow'></Box>
            <CardMedia
              style={{
                opacity: `${isHovering ? (isImageFade ? '0.4' : '1') : '1'}`,
                position: 'relative',
                margin: '0',
              }}
              alt='#GMIS2021'
              className='zIndex-1'
              component='img'
              height={height || '100%'}
              image={`${process.env.GATSBY_CMS_API_URL}/static/${image}`}
              onChange={(e) => console.log(e)}
            />

            <Box position='relative' zIndex='tooltip' mb={2}>
              <Scrollbar maxHeight={200}>
                <CardContent style={{ minHeight: '150px', maxHeight: '150px' }}>
                  <Box>
                    <Box fontWeight='fontWeightBold' className='text-upper-case'>
                      <Typography gutterBottom variant='body1' color='textPrimary'>
                        {title}
                      </Typography>
                    </Box>
                    <Box className='text-upper-case'>
                      <Typography gutterBottom variant='body2' color='textPrimary'>
                        {body}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Scrollbar>
              {readMore && isHovering ? (
                <CardActions>
                  <CustomLink type={2} linkText='Read More' linkUrl={readMore} />
                </CardActions>
              ) : null}
            </Box>
          </Box>
        </Card>
      </Fade>
      <Dialog onClose={handleCloseDialog} aria-labelledby='customized-dialog-title' open={openDialog}>
        <DialogTitle
          style={{
            minWidth: isMobile ? '80vw' : '40vw',
          }}
          id='customized-dialog-title'
          onClose={handleCloseDialog}>
          <Box display='flex'>
            {title && (
              <Box display='flex' flexDirection='column' justifyContent='center' flexGrow={1}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                  }}
                  variant='body1'>
                  {title}
                </Typography>
              </Box>
            )}
            <Box>
              <IconButton color='primary' onClick={handleCloseDialog}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent
          style={{
            height: '400px',
            // minWidth: isMobile ? '80vw' : '40vw',
          }}>
          <Box width={1} height='380px'>
            <Scrollbar isAlwaysOn={true} maxHeight='360px'>
              <Typography gutterBottom dangerouslySetInnerHTML={{ __html: bio }} variant='body2' color='textPrimary' />
            </Scrollbar>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AnimatedCard;
