import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
// import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import { Grid, Box, Typography, TextField } from '@material-ui/core';
import { useDebounce } from 'use-debounce';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import CustomGrid from '../../components/custom-grid/custom-grid';
import CustomGridItem from '../../components/custom-grid/custom-grid-item';
import AnimatedCard from '../../components/card-image-title-description/animated-card';

const SpeakersPage = ({ pageContext: { _sortedSpeakersArray, _speakerYearsArray } }) => {
  const [years, setYears] = useState(_speakerYearsArray.sort((a, b) => b - a));
  const [hasResult, setHasResult] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredSpeaker, setFilteredSpeaker] = useState({});
  const [value] = useDebounce(searchText, 1000);
  const [speakers] = useState(_sortedSpeakersArray);

  useEffect(() => {
    let _allYears = years;
    if (value.length > 0) {
      let _filteredSpeaker = [];
      let _filteredYears = [];

      speakers.forEach((_speaker) => {
        if (
          _speaker.firstName.toLowerCase().includes(value.toLocaleLowerCase()) ||
          (_speaker.salutation &&
            _speaker.salutation.name &&
            _speaker.salutation.name.toLowerCase().includes(value.toLocaleLowerCase())) ||
          _speaker.lastName.toLowerCase().includes(value.toLocaleLowerCase()) ||
          _speaker.organization.toLowerCase().includes(value.toLocaleLowerCase()) ||
          _speaker.designation.toLowerCase().includes(value.toLocaleLowerCase())
        ) {
          _filteredSpeaker.push(_speaker);
          if (!_filteredYears.includes(_speaker.year)) {
            _filteredYears.push(_speaker.year);
          }
        }
      });

      if (_filteredSpeaker.length > 0) {
        setHasResult(true);
        setFilteredSpeaker(_filteredSpeaker);

        setYears(_filteredYears);
      } else {
        setYears([]);
        setHasResult(false);
      }
    } else {
      setHasResult(true);
      setFilteredSpeaker(speakers);
      setYears(_allYears);
    }

    return () => {};
  }, [value]);

  return (
    <Layout>
      <SEO
        lang='en'
        title='Speakers | Global Manufacturing & Industrialisation Summit'
        keywords='virtual industries Summit Speakers, Professional Speakers For Global manufacturing & Industrialisation Summit, GMIS 2022 Speakers, industry summit Speakers, Previous Speakers GMIS Summit, GMIS 2020 Speakers, Global manufacturing innovations expertise Summit, manufacturing industries  speakers in Dubai'
        description='Listen to Professional Speakers and expertise at Global Manufacturing & Industrialisation Summit, UAE. Visit GMIS today!'
      />
      <SectionContainerLayoutWithFilter
        title='Speakers'
        isViewAll
        isDivider
        filters={_speakerYearsArray.sort((a, b) => b - a)}
        baseLink='/speakers'>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={6}>
            <TextField
              placeholder='Search Speakers'
              label='Search Speakers'
              color='secondary'
              variant='outlined'
              fullWidth
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Grid>
          {hasResult ? (
            years.map((_year, yearIndex) => (
              <Grid key={yearIndex} item xs={12}>
                <Typography gutterBottom variant='h6' className='text-uppsercase'>
                  <Box my={3}>{_year}</Box>
                </Typography>
                <CustomGrid>
                  {filteredSpeaker.map((_speaker, speakerIndex) =>
                    _speaker.year === _year ? (
                      <CustomGridItem key={speakerIndex}>
                        {/* <CardImageTitleDescription
                          isImgTag
                          image={_speaker.file.code}
                          title={
                            _speaker.SalutationId === 7
                              ? _speaker.firstName + ' ' + _speaker.lastName
                              : _speaker.salutation.name + ' ' + _speaker.firstName + ' ' + _speaker.lastName
                          }
                          body={
                            _speaker.organization && _speaker.organization.length > 1
                              ? _speaker.designation + ', ' + _speaker.organization
                              : _speaker.designation
                          }
                          disabelRippleEffect
                        /> */}
                        <AnimatedCard
                          bio={_speaker.bio ? _speaker.bio : null}
                          image={_speaker.file.code}
                          title={
                            _speaker.salutationId === 7 || _speaker.salutation.name === 'NULL'
                              ? _speaker.firstName + ' ' + _speaker.lastName
                              : _speaker.salutation.name + ' ' + _speaker.firstName + ' ' + _speaker.lastName
                          }
                          body={
                            _speaker.organization && _speaker.organization.length > 1
                              ? _speaker.designation + ', ' + _speaker.organization
                              : _speaker.designation
                          }
                        />
                      </CustomGridItem>
                    ) : null
                  )}
                </CustomGrid>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Box m={8} p={{ xs: 0, sm: 8 }} fullWidth textAlign='center'>
                <Typography color='textSecondary' variant='button'>
                  No Speakers Found
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default SpeakersPage;
